export const ApiPaths = {
    AuthLogout: '/rest/core/v1/users/logout/',
    Auth2FAInit: '/rest/core/v1/users/2fa_init/',
    Auth2FAConfirm: '/rest/core/v1/users/2fa_confirm/',
    AuthSetPhone: '/rest/core/v1/users/phone/',
    AuthPhoneConfirm: '/rest/core/v1/users/phone_confirm/',
    AuthResetPasswordRequest: '/rest/core/v1/users/password/recover/',
    AuthResetPasswordInit: '/rest/core/v1/users/password/recover/init/',
    AuthResetPasswordConfirm: '/rest/core/v1/users/password/recover/confirm/',
    AuthEmailConfirmForRegistration: '/auth/v1/confirm/{token}',
    AuthEmailConfirmForResettingPassword: '/rest/core/v1/users/validate_confirm_token/{token}/',
    Users2faInit: '/rest/core/v1/users/2fa_init/',
    Users2faConfirm: '/rest/core/v1/users/2fa_confirm/',
    UsersRefresh: '/rest/core/v1/users/refresh/',
    UsersPhone: '/rest/core/v1/users/phone/',
    UsersBlockSession: '/rest/core/v1/users/block_session/',
    UsersPhoneConfirm: '/rest/core/v1/users/phone_confirm/',
    UsersRole: '/rest/core/v1/users/role/',
    Users: '/rest/core/v1/users/',
    UsersFullProfile: '/rest/core/v1/users/full_information/{id}/',
    UsersAgreements: '/rest/core/v1/users/agreements/',
    UsersAgreementsByUser: '/rest/core/v1/users/agreements/{user_uuid}/',
    UsersMakeFullBlock: '/rest/core/v2/users/full_block/',
    UsersMakePartlyBlock: '/rest/core/v2/users/partly_block/',
    UsersMakeFullUnblock: '/rest/core/v2/users/full_unblock/',
    UsersMakePartlyUnblock: '/rest/core/v2/users/partly_unblock/',
    UsersAuthorizationDataById: '/rest/core/v1/users/authorization_data/{id}/',
    GetUserBlockingEventByUuid: '/rest/core/v1/users/user_block_events/{uuid}/',
    GetUsersBlockingEvents: '/rest/core/v1/users/user_block_events/',
    UsersCheckSessionById: '/rest/core/v1/users/check_session/{id}/',
    UsersChangeAuthLoginSet: '/rest/core/v1/users/change_auth_data_login_set/',
    UsersChangeAuthLoginConfirm: '/rest/core/v1/users/change_auth_data_login_confirm/',
    UsersChangeEmailInitAdminConfirm: '/rest/core/v1/users/change_auth_data_email_confirm/',
    UsersChangeAuthPhoneSet: '/rest/core/v1/users/change_auth_data_set_phone/',
    UsersChangeAuthPhoneConfirm: '/rest/core/v1/users/change_auth_data_phone_confirm/',
    UsersChangePasswordByAdminConfirm: '/rest/core/v1/users/change_auth_data_password_confirm/',
    UsersTerminationByClient: '/rest/core/v1/users/service_termination_by_user/',
    UsersTerminationByAdmin: '/rest/core/v1/users/service_termination_by_operator/',
    UsersRegistry: '/rest/core/v1/users/registry/',
    Services: `/rest/core/v1/services/{name}`,
    DfaV2: '/rest/core/v2/dfa/',
    Dfa: `/rest/core/v1/dfa/`,
    DfaById: `/rest/core/v1/dfa/{dfaId}/`,
    DfaPatchRedeemDate: '/rest/core/v1/dfa/{dfaId}/entry/redeem_date/',
    DfaAvailable: '/rest/core/v1/dfa/{dfaId}/available/',
    DfaAvailableV2: '/rest/core/v2/dfa/{dfaId}/available/',
    DfaStatus: `/rest/core/v1/dfa/status/{status}/`,
    DfaConfirm: `/rest/core/v1/dfa/{dfaId}/confirm/`,
    DfaReject: `/rest/core/v1/dfa/{dfaId}/reject/`,
    DfaRejectV2: '/rest/core/v2/dfa/{dfaId}/reject/',
    DfaIssuerConfirmation: '/rest/core/v2/dfa/{dfaId}/issuer_confirmation/',
    DfaMakeIssueFailed: '/rest/core/v1/dfa/{dfaId}/issue/failure/',
    DfaEntryEmitterLink: `/rest/core/v1/dfa/{dfaId}/entry/emitter_link/`,
    DfaEntryOisLink: `/rest/core/v1/dfa/{dfaId}/entry/ois_link/`,
    DfaIssue: `/rest/core/v2/dfa/{dfaId}/issue/`,
    DfaConfirmSecondary: '/rest/core/v3/dfa/{dfaId}/confirm/secondary/',
    DfaConfirmToLawyer: '/rest/core/v2/dfa/{asset_id}/lawyer_approval/',
    DfaConfirmToTeller: '/rest/core/v2/dfa/{asset_id}/teller_approval/',
    DfaConfirmToSpec: '/rest/core/v2/dfa/{asset_id}/spec_approval/',
    DfaToModification: '/rest/core/v2/dfa/{asset_id}/modification/',
    DfaToCollectionStarted: '/rest/core/v2/dfa/{asset_id}/collection_started/',
    DfaOrdersByDfaId: `/rest/core/v1/dfa/{dfaId}/orders/`,
    DfaInvestOrdersV4: '/rest/core/v4/dfa/invest_order/',
    DfaOrders: `/rest/core/v1/dfa/orders/`,
    DfaOrdersByOrderId: `/rest/core/v1/dfa/orders/{orderId}/`,
    DfaOrderAcceptedByEmitter: `/rest/core/v2/dfa/order/accepted_by_emitter/`,
    DfaOrderRefusedByEmitter: `/rest/core/v2/dfa/order/refused_by_emitter/`,
    DfaDealsById: `/rest/core/v1/dfa/{dfaId}/deals/`,
    DfaDeals: `/rest/core/v1/dfa/deals/`,
    DfaCounter: `/rest/core/v1/dfa/number/`,
    DfaApprovalHistoryByDfaId: '/rest/core/v1/dfa/approval_history/{dfaId}/',
    DfaApprovalHistoryForAdmin: '/rest/core/v1/dfa/approval_history_by_admin/{dfaId}/',
    DfaDealsByDealId: `/rest/core/v1/dfa/deals/{dealId}/`,
    DfaDevEmission: `/rest/core/v1/dfa/dev/{dfaId}/emission/`,
    DfaCheckTicker: '/rest/core/v1/dfa/{dfaTickerSymbol}/check_ticker/',
    SecondaryDfaOrderSell: '/rest/core/v2/secondary_dfa/order/sell/',
    SecondaryDfaOrderSellReject: '/rest/core/v2/secondary_dfa/order/sell/reject/',
    SecondaryDfaOrderSellCancel: '/rest/core/v2/secondary_dfa/order/sell/cancel/',
    SecondaryDfaOrderBuyByOrderId: '/rest/core/v2/secondary_dfa/order/buy/{sellOrderId}/',
    SecondaryDfaOrderBuy: '/rest/core/v2/secondary_dfa/order/buy/',
    SecondaryDfaOrderBuyReject: '/rest/core/v2/secondary_dfa/order/buy/reject/',
    SecondaryDfaOrderBuyCancel: '/rest/core/v2/secondary_dfa/order/buy/cancel/',
    SecondaryDfaOrderSellByOrderId: '/rest/core/v2/secondary_dfa/order/sell/{buyOrderId}/',
    SecondaryDfaDeals: '/rest/core/v1/secondary_dfa/deals/',
    RepaymentDfaRepaymentV4: '/rest/core/v4/repayment_dfa/repayment/',
    RepaymentDfaDeals: '/rest/core/v1/repayment_dfa/deals/',
    RepaymentDfaConfirmByInvestorV2: '/rest/core/v2/repayment_dfa/confirm/by_investor/',
    RepaymentDfaPayoffAgreements: '/rest/core/v1/repayment_dfa/payoff_agreements/',
    RepaymentDfaByOperator: '/rest/core/v1/repayment_dfa/operator_repayment/',
    RepaymentMatureInvestor: '/rest/core/v1/repayment_dfa/operator_repayment/investor/',
    RepaymentMatureAsset: '/rest/core/v1/repayment_dfa/operator_repayment/asset/',
    SecondaryDfaOrders: '/rest/core/v1/secondary_dfa/orders/',
    WalletDfaOwners: '/rest/core/v1/wallet_dfa/{dfaId}/owners/',
    WalletDfaByUserId: '/rest/core/v1/wallet_dfa/{userId}/',
    WalletDfaBalances: '/rest/core/v1/wallet_dfa/{userId}/balances/',
    WalletEncumbranceGetByUser: '/rest/core/v1/wallet_dfa/encumbrance/locks/{user_uuid}/',
    WalletEncumbranceGetById: '/rest/core/v1/wallet_dfa/encumbrance/{encumbrance_uuid}/',
    WalletEncumbranceSetByUserV2: '/rest/core/v2/wallet_dfa/encumbrance/set_by_user/',
    WalletEncumbranceRemoveByUserV2: '/rest/core/v2/wallet_dfa/encumbrance/remove_by_user/',
    WalletEncumbranceSetByOperator: '/rest/core/v1/wallet_dfa/encumbrance/set_by_operator/',
    WalletEncumbranceSetByOperatorV2: '/rest/core/v2/wallet_dfa/encumbrance/set_by_operator/',
    WalletEncumbranceRemoveByOperator: '/rest/core/v1/wallet_dfa/encumbrance/remove_by_operator/',
    WalletEncumbranceRemoveByOperatorV2: '/rest/core/v2/wallet_dfa/encumbrance/remove_by_operator/',
    FilesUpload: '/rest/core/v1/files/upload/',
    FilesById: '/rest/core/v1/files/{id}/',
    FilesDocx: '/rest/core/v1/files/docx/',
    FilePaymentOrder: '/rest/core/v1/money/payment_orders/file/',
    MoneyTransactionCredit: '/rest/core/v1/money/transaction/credit/',
    MoneyTransactionDebit: '/rest/core/v1/money/transaction/debit/',
    MoneyReserveTransfer: '/rest/core/v1/money/reserve/transfer/',
    MoneyReserveReleaseTransferSauToBank:
        '/rest/core/v1/money/reserve/transfer_init_sau_to_bank_account/',
    MoneyAccountBalance: '/rest/core/v1/money/account/{accountId}/balance/',
    MoneyAccountTransactions: '/rest/core/v1/money/account/{accountId}/transactions/',
    MoneyTransactions: '/rest/core/v1/money/transactions/',
    MoneyTransactionByTransactionId: '/rest/core/v1/money/transaction/{transactionId}/',
    MoneyAccountReserves: '/rest/core/v1/money/account/{accountId}/reserves/',
    MoneyAccountReservesPaging: '/rest/core/v1/money/reserves/',
    MoneyAccountByAccountId: '/rest/core/v1/money/account/{account_id}/',
    MoneyAccountsByAccountId: '/rest/core/v1/money/accounts/{accountId}/',
    MoneyAccountsBalanceByAccountIdV2: '/rest/core/v2/money/accounts/{accountId}/',
    MoneyAccounts: '/rest/core/v1/money/accounts/',
    MoneyAccountCheckBlock: '/rest/core/v1/money/account/{accountId}/check-block/',
    MoneyAccountCheckSum: '/rest/core/v1/money/account/{account_uuid}/check-sum/{sum}',
    MoneyPaymentOrders: '/rest/core/v1/money/payment_orders/',
    MoneyPaymentOrdersByPaymentOrderId: '/rest/core/v1/money/payment_orders/{payment_order_id}/',
    MoneyReserveTransferConfirmSauToBankAccount:
        '/rest/core/v1/money/reserve/transfer_confirm_sau_to_bank_account/',
    MoneyReservePaymentOrderCancelSauToBankAccount:
        '/rest/core/v1/money/reserve/payment_order_cancel_sau_to_bank_account/',
    MoneyReserveTransferCancelSauToBankAccount:
        '/rest/core/v1/money/reserve/transfer_cancel_sau_to_bank_account/',
    MoneyPaymentOrdersGenerate: '/rest/core/v1/money/payment_orders/generate/',
    PaymentOrdersFile: '/rest/core/v1/payment_orders/file/',
    MoneyUserBankAccountsByUserId: '/rest/core/v1/money/user_bank_accounts/{user_uuid}/',
    MoneyNominalAccounts: '/rest/core/v1/money/nominal_account/',
    MoneyNominalAccountById: '/rest/core/v1/money/nominal_account/{nominal_account_uuid}/',
    MoneyNominalAccountBind: '/rest/core/v1/money/account/bind/',
    MoneyRelatedAccountsByNominalAccount:
        '/rest/core/v1/money/nominal_account/{nominal_account_uuid}/accounts/',
    BankAccountsCheckUnique: '/rest/core/v1/money/user_bank_accounts/check-uniqueness/',
    ProfileCommon: '/rest/core/v1/profile/common/',
    ProfileByTypeAndId: '/rest/core/v1/profile/{type}/{uuid}/',
    ProfilePublicInformationByTypeAndId:
        '/rest/core/v1/profile/{profileType}/public_information/{uuid}/',
    ProfileLegalEntity: '/rest/core/v1/profile/legal_entity/',
    ProfileLegalEntityByProfileId: '/rest/core/v1/profile/legal_entity/{profileId}/',
    ProfileLegalEntityPublicInformation:
        '/rest/core/v1/profile/legal_entity/public_information/{profileId}/',
    ProfileBusinessman: '/rest/core/v1/profile/businessman/',
    ProfileBusinessmanByProfileId: '/rest/core/v1/profile/businessman/{profileId}/',
    businessmanPublicInformation: '/rest/core/v1/businessman/public_information/{profileId}/',
    ProfileBeneficialOwner: '/rest/core/v1/profile/beneficial_owner/',
    ProfileBeneficialOwnerByProfileId: '/rest/core/v1/profile/beneficial_owner/{profileId}/',
    ProfileIndividual: '/rest/core/v1/profile/individual/',
    ProfileIndividualByProfileId: '/rest/core/v1/profile/individual/{profileId}/',
    ProfileIndividualPublicInformation:
        '/rest/core/v1/profile/individual/public_information/{profileId}/',
    ProfileAgent: '/rest/core/v1/profile/agent/',
    ProfileAgentByProfileId: '/rest/core/v1/profile/agent/{profileId}/',
    ProfileAgentAgentsByCreatorId: '/rest/core/v1/profile/agent/agents/{creator_uuid}/',
    ProfileType: '/rest/core/v1/profile/type/',
    ProfileChangeStatus: '/rest/core/v1/profile/change_status/',
    ProfileChangeStatusV2: '/rest/core/v2/adapter/accounts/',
    ProfileApprovalHistory: '/rest/core/v1/profile/approval_history/{user_uuid}/',
    ProfileClientStatuses: '/rest/core/v1/profile/client_statuses/',
    ProfileCheckInn: '/rest/core/v1/profile/check_inn/',
    ProfileClients: '/rest/core/v1/profile/clients/',
    ProfileGetUidByInn: '/rest/core/v1/profile/user/',
    ProfileGetUidsByInnType: '/rest/core/v1/profile/users/',
    ProfileIdentification: '/rest/core/v1/profile/identification_info/{user_uuid}/',
    PutProfileUpdateByUuid: '/rest/core/v1/profile/user/{user_uuid}/',
    ProfileBlacklistByUuid: '/rest/core/v1/blacklists/user/{user_uuid}/',
    ProfileApplications: '/rest/core/v1/profile/applications/',
    AdapterDfaDeploy: '/rest/core/v1/adapter/dfa/deploy/',
    AdapterDfaInvest: '/rest/core/v1/adapter/dfa/invest/',
    AdapterDfaIssue: '/rest/core/v1/adapter/dfa/issue/',
    AdapterDfaMature: '/rest/core/v1/adapter/dfa/mature/',
    AdapterDfaPayoff: '/rest/core/v1/adapter/dfa/payoff/',
    AdapterDfaPaid: '/rest/core/v1/adapter/dfa/paid/',
    AdapterDfaTransferByTransferType: '/rest/core/v1/adapter/dfa/transfer/{transferType}/',
    AdapterDfaInjunction: '/rest/core/v1/adapter/dfa/injunction/',
    AdapterDfaLiftingInjunction: '/rest/core/v1/adapter/dfa/lifting-injunction/',
    TariffPlane: '/rest/core/v2/tariff/tariffs/',
    TariffPlaneArchive: '/rest/core/v2/tariff/{tariff_id}/archive/',
    TariffFees: '/rest/core/v2/tariff/fees/',
    TariffGrids: '/rest/core/v2/tariff/grids/',
    TariffGrid: '/rest/core/v2/tariff/grids/{grid_id}/',
    TariffGridActivate: '/rest/core/v2/tariff/grids/{grid_id}/activate/',
    TariffGridDeactivate: '/rest/core/v2/tariff/grids/{grid_id}/deactivate/',
    TariffCalculateFee: '/rest/core/v2/tariff/calculate-fee/',
    TariffLinkUser: '/rest/core/v2/tariff/link_user/',
    TariffUnlinkUser: '/rest/core/v2/tariff/unlink_user/{link_id}/',
    TariffUsers: '/rest/core/v2/tariff/{tariff_id}/users/',
    TariffAssets: '/rest/core/v2/tariff/{tariff_id}/assets/',
    TariffLinkAsset: '/rest/core/v2/tariff/link_asset/',
    TariffUnlinkAsset: '/rest/core/v2/tariff/unlink_asset/{link_id}/',
    TariffByUser: '/rest/core/v2/tariff/by_user/{user_id}/',
    TariffByAsset: '/rest/core/v2/tariff/by_asset/{asset_id}/',
    TariffTariffAssignmentLog: '/rest/core/v2/tariff/tariff-assignment-log/',
    TransferDfaOrderInitV2: '/rest/core/v2/transfer_dfa/order/',
    TransferDfaOrderAcceptV2: '/rest/core/v2/transfer_dfa/order/accept/',
    TransferDfaDealByOperatorV2: '/rest/core/v2/transfer_dfa/order/deal_by_operator/',
    TransferDfaOrderExecuteV2: '/rest/core/v2/transfer_dfa/order/execute/',
    TransferDfaOrderCancelByOperatorV2: '/rest/core/v2/transfer_dfa/order/cancel_by_operator/',
    TransferCancelByOfferorV2: '/rest/core/v2/transfer_dfa/order/cancel_by_offeror/',
    TransferDfaOrderAccept: '/rest/core/v1/transfer_dfa/order/accept/',
    TransferDfaOrderByOrderId: '/rest/core/v1/transfer_dfa/order/{orderId}/',
    TransferDfaOrdersCalculateAcceptFee: '/rest/core/v2/transfer_dfa/order/accept/calculate_fee',
    TransferDfaOrdersByGlobalId: '/rest/core/v1/transfer_dfa/orders/{globalId}',
    TransferDfaOrders: '/rest/core/v1/transfer_dfa/orders/',
    TransferDfaDeals: '/rest/core/v1/transfer_dfa/deals/',
    TransferDfaDealByOperator: '/rest/core/v1/transfer_dfa/deal_by_operator/',
    TransferCancelByOwner: '/v1/transfer_dfa/order/{uuid}/cancel_by_owner/',
    TransferReject: '/v1/transfer_dfa/order/{uuid}/reject/',
    TransferCancelByRecipient: '/v1/transfer_dfa/order/{uuid}/cancel_by_recipient/',
    QualifyRegistrySet: '/rest/core/v1/investor_registry/set_qualified/',
    QualifyInfo: '/rest/core/v1/investor_registry/investors/{user_uuid}/',
    QualifyRegistryDelete: '/rest/core/v1/investor_registry/set_unqualified/',
    QualifyRegistryRequest: '/rest/core/v1/investor_registry/applications/',
    QualifyRegistryRequestPost: '/rest/core/v1/investor_registry/application/',
    QualifyRegistryEvents: '/rest/core/v1/investor_registry/application_events/',
    LockDfaByUserId: '/rest/core/v1/lock_dfa/{user_id}/',
    LockDfaInjunction: '/rest/core/v1/lock_dfa/injunction/',
    LockDfaLiftingInjunction: '/rest/core/v1/lock_dfa/lifting-injunction/',
    DfaEntry: '/rest/core/v1/dfa/{id}/entry/{endpoint}/',
    EmitmentStop: '/rest/core/v1/dfa/{asset_id}/issue/stop/',
    EmitmentUnstop: '/rest/core/v1/dfa/{asset_id}/issue/unstop/',
    EmitmentRevoke: '/rest/core/v1/dfa/{asset_id}/revoke/',
    CertificatesUserCertificates: '/rest/core/v1/csp_certs/certificates/',
    CertificatesUserCertificateByUid: '/rest/core/v1/csp_certs/certificates/{uuid}/',
    AdapterTransactionV2: '/rest/core/v2/adapter/transaction/',
    AdapterTransactionsV2: '/rest/core/v2/adapter/transactions/',
    WalletDfaInjunctionSet: '/rest/core/v2/wallet_dfa/injunction/set/',
    WalletDfaInjunctionRemove: '/rest/core/v2/wallet_dfa/injunction/remove/',
    CalculateRepaymentFee: '/rest/core/v1/repayment_dfa/calculate_fee/',
    CalculateInvestorRepaymentFee:
        '/rest/core/v1/repayment_dfa/operator_repayment/investor/calculate_fee/',
    CalculateMatureRepaymentFee:
        '/rest/core/v1/repayment_dfa/operator_repayment/asset/calculate_fee/',
    DealsList: '/rest/core/v1/deals/',
    DealByUuid: '/rest/core/v1/deals/{deal_id}/',
    Employee: '/rest/core/v1/employee/',
    EmployeeEdit: '/rest/core/v1/employee/{user_uuid}/',
    EmployeeBlock: '/rest/core/v1/employee/block/{user_uuid}/',
    EmployeeUnBlock: '/rest/core/v1/employee/unblock/{user_uuid}/',
    EmployeeArchive: '/rest/core/v1/employee/archive/{user_uuid}/',
    EmployeeChangePhoneInitAdmin: '/rest/core/v1/employee/change_auth_data_set_phone_init/',
    EmployeeChangePasswordInitAdmin: '/rest/core/v1/employee/change_auth_data_set_password_init/',
    Documents: '/rest/core/v1/documents_generator/documents',
    ExternalRates: '/rest/core/v1/external_rates/{index}',
    WalletDfaOwnersCount: '/rest/core/v1/wallet_dfa/owners/{asset_id}/count/',
    GetAccountByType: '/rest/core/v1/money/account/by_type/{accountType}/',
    ProfileLegalEntityPrivateInformation:
        '/rest/core/v1/profile/legal_entity/private_information/{uuid}/',
    ProfileBusinessmanPrivateInformation:
        '/rest/core/v1/profile/businessman/private_information/{uuid}/',
    ProfileIndividualPrivateInformation:
        '/rest/core/v1/profile/individual/private_information/{uuid}/',
    ProfilePrivateInformationByTypeAndId:
        '/rest/core/v1/profile/{type}/private_information/{uuid}/',
    TransferDfaOrderSign: '/rest/core/v2/transfer_dfa/order/sign/',
    MoneyAccountBindByUuid: '/rest/core/v1/money/account/bind_by_uuid/',
    TransferInitSauByUuidToBankAccount:
        '/rest/core/v1/money/reserve/transfer_init_sau_by_uuid_to_bank_account/',
    TransfersV2: '/rest/core/v2/transfers/',
    TransfersV2DetailsByUuid: '/rest/core/v2/transfers/{uuid}/',
    CalendarWorkday: '/rest/core/v1/calendar/workday/',
    CalendarCalculateCouponPayment: '/rest/core/v1/calendar/calculate/coupon_payment_calendar',
    DfaCouponPayment: '/rest/core/v1/dfa/coupon_payment/',
    EarlyRepaymentOperatorRepayment: '/rest/core/v1/early_repayment_request/operator_repayment/',
    EarlyRepaymentRequestByUuid: '/rest/core/v1/early_repayment_request/{requestUuid}',
    EarlyRepaymentConfirmByInvestor: '/rest/core/v1/early_repayment_request/confirm/by_investor/',
    EarlyRepaymentCalculateFee: '/rest/core/v1/early_repayment_request/calculate_fee/',
    EarlyRepaymentConfirmByIssuer: '/rest/core/v1/early_repayment_request/confirm/by_issuer/',
    EarlyRepaymentrequest: '/rest/core/v1/early_repayment_request/',
    EarlyRepaymentEntryRedeemDate: '/v1/dfa/{id}/entry/redeem_date',
    TaxOperations: '/rest/core/v1/tax/operations/',
    TaxOperationsSummary: '/rest/core/v1/tax/operations/summary/',
    TaxTaxes: '/rest/core/v1/tax/taxes/',
    TaxTaxesSummary: '/rest/core/v1/tax/taxes/summary/',
    TaxWithholdings: '/rest/core/v1/tax/withholdings/',
    TaxWithholdingsSummary: '/rest/core/v1/tax/withholdings/summary/',
    TaxWithholdingsAnnual: '/rest/core/v1/tax/withholdings/annual/',
    TaxWithdrawalByOperator: '/rest/core/v1/tax/withdrawal/by_operator/',
    CurrentUser: '/rest/core/v2/current-user',
    BankDetailsByUiserId: '/rest/core/v1/profile/bank_details/{userUUID}/',
    InitPhoneReset: '/rest/auth/v1/init-phone-reset',
    InitEmailReset: '/rest/auth/v1/init-email-reset',
    InitPasswordReset: '/rest/auth/v1/init-password-reset',
    BackofficeSendPhoneResetEmail: '/rest/auth/v1/backoffice/users/{id}/send-phone-reset-email',
    BackofficeSendEmailResetEmail: '/rest/auth/v1/backoffice/users/{id}/send-email-reset-email',
    BackofficeSendPasswordResetEmail:
        '/rest/auth/v1/backoffice/users/{id}/send-password-reset-email',
    ReportTaxSettlementsTypeDirect: '/rest/core/v1/report/tax/settlements_type_direct/',
    AdminsAgreements: '/rest/core/v1/users/admins/agreements/',
    SpvbInvestOrder: '/spvb-public/invests/{id}',
    SpvbInvestOrderPrepare: '/spvb-public/invests/{id}/prepare',
    SpvbInvestOrderSign: '/spvb-public/invests/{id}/sign',
}
